<template lang="pug">
v-main(fluid class="pa-0")
  v-img(src="banner4.png" loading="lazy" class="align-end").pa-0.ma-0
  v-row(justify="center")
    v-col(cols="11")
      h1.pl-5.mb-4.primary.white--text(style="box-shadow: 0px 4px #46755f;") La iniciativa
      p La iniciativa toma como inspiración inicial el documento de Transparencia Internacional #[a(href="https://www.tm.org.mx/wp-content/uploads/2020/03/recomendaciones_ESPT.pdf" target="_blank") "Contrataciones Públicas en Estados de Emergencia"], donde se hace énfasis en la importancia de que las autoridades garanticen el uso adecuado de los recursos públicos disponibles para mitigar, contener y subsanar los efectos negativos derivados de las mismas.

      p #[strong Transparencia Ante la Contingencia], es un ejercicio de transparencia proactiva el cual busca transparentar de manera focalizada los procesos de contratación que se deriven de las acciones para combatir la contingencia del COVID-19 a través de una herramienta electrónica que consiste en capturar y presentar en formatos de datos abiertos, de manera confiable, oportuna y veraz la información pública; misma que se pondrá al alcance de todos los sujetos obligados del estado de Nuevo León. En ese sentido, se toma en cuenta que a nivel estatal y de acuerdo al Padrón de Sujetos Obligados en Materia de Transparencia y Acceso a la Información Pública del Estado de Nuevo León (SE-V2-2020), existe un total de 195 sujetos obligados; de los cuales, 193 tienen el carácter de ser directos y 2 de ser indirectos.

    v-col(cols="11")
      h1 Línea del Tiempo
      v-timeline
        v-timeline-item(
          v-for="(hecho, i) in hechos"
          :key="i"
          :color="hecho.color"
          small
        )
          template(v-slot:opposite).ma-0
            span(
              :class="`headline font-weight-bold`"
              v-text="hecho.fecha"
            ).text-center
            v-img(:src="hecho.titulo" contain height="100px" :class="`headline`").mt-2
          .py-4
            .text-caption.text-sm-body-1 {{ hecho.descripcion }}


    v-col(cols="11")
      h1 Webinars destacables
      v-row.text-center(justify="center")
        v-col(cols="11" md="6")
          iframe(width="100%" height=300 src="https://www.youtube.com/embed/6UttzXzkzhs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
          h3 3 de junio de 2020 
          p Webinar : "Transparencia proactiva en tiempos de COVID-19"
        v-col(cols="11" md="6")
          iframe(width="100%" height=300 src="https://www.youtube.com/embed/shcEGp9Tv5g" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
          h3 10 de julio de 2020 
          p Webinar: "Acciones de Transparencia Proactiva ante la contingencia, retos y desafíos desde lo local"
  
    v-col(cols="11")
      h1.pl-5.mb-4.primary.white--text(style="box-shadow: 0px 4px #46755f;") Desarrollo TAC 2.0
      p El pasado 20 de octubre de 2020, la COTAI en conjunto con Redes Quinto Poder IDEA A.C., lanzaron la convocatoria para participar en el reto tecnológico denominado #[a(href="https://cotai.org.mx/hackaton2020/" target="_blank") “Hackatón Transparencia ante la Contingencia”] cuya misión fue desarrollar una plataforma digital en página web, alimentada a través de las bases de datos recopiladas en el micrositio de la COTAI, denominado “Transparencia Ante la Contingencia”. Dicha herramienta electrónica deberá ser de fácil acceso y uso, ser diseñada en código abierto, que permita la comparación de presupuestos, productos de bienes y servicios, así como los gastos ejercidos para combatir el COVID-19.
      p El 23 de noviembre de 2020 se llevó a cabo la ceremonia de premiación del Hackatón en donde el jurado calificador reconoció las mejores iniciativas por su innovación y posibilidad de hacerlas realidad. Las y los integrantes de dos equipos ganadores sumaron esfuerzos y atendieron la iniciativa de la COTAI para desarrollar en conjunto el actual micrositio.

    v-col(cols="11")
      h1 Elaboración del Sitio Web
      v-row(justify="center")
        v-col.text-center(cols="12" md="6")
          v-img(src="capybara.jpg" contain width="80%").mx-auto
          h2.primary--text Equipo Capybara
          p.ma-0.text-caption Diana Ofelia López Cantú y Kevin Daniel Radtke Garza
          p.text-caption.text--secondary Tecnológico de Monterrey
        v-col.text-center(cols="12" md="6")
          v-img(src="sofsolutions.jpg" contain width="80%").mx-auto
          h2.primary--text Equipo Sofsolution
          p.ma-0.text-caption Eduardo Daniel Hernández Zavala y Edgar Giovani Tamez García
          p.text-caption.text--secondary Facultad de Ingeniería Mecánica y Eléctrica de la UANL

</template>

<script>
export default {
  data: () => ({
    hechos: [
      {
        color: '#A2D9CE',
        fecha: '11 de marzo',
        titulo: 'linea-1.png',
        descripcion: 'Primer caso de COVID-19 en Nuevo León, inicio del proyecto "Transparencia Ante la Contingencia".',
      },
      {
        color: '#45B39D',
        fecha: '2 de abril',
        titulo: 'linea-2.png',
        descripcion: 'La COTAI pone a la disposición de los ciudadanos un micrositio que servirá como herramienta de consulta sobre los procesos que deriven de la contingencia sanitaria.',
      },
      {
        color: '#138D75',
        fecha: '28 de abril',
        titulo: 'linea-3.png',
        descripcion: 'La COTAI libera los datos correspondientes a los procesos de compra.',
      },
      {
        color: '#0E6655',
        fecha: '27 de junio',
        titulo: 'linea-4.png',
        descripcion: 'La COTAI en colaboración con Redes Quinto Poder IDEA A.C., impulsan la segunda etapa del micrositio TAC. ',
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>

</style>
